import "./CreateLink.css"
import { useCallback, useEffect, useState } from 'react'
import request from '../../../../utils/request'
import Message from "../../../Modules/Message/Message";
import ResultLink from "./ResultLink/ResultLink";
import Spinner from "../../../Modules/Loader/Spinner";


const CreateButtonBody = ({ loading }) => {
  if (loading) return <span className="ButtonText">
    <Spinner size={18}/>
  </span>

  return <span className="ButtonText">
    Сократить
  </span>
}

export default function CreateLink({ setSnackbar }) {
  const [url, setUrl] = useState('')
  const [result, setResult] = useState(null)
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const createLink = useCallback(async (e) => {
    if (loading) return
    if (e?.key && e?.key !== 'Enter') return 
    if (url.length <= 3 | loading) return

    setLoading(true)

    try {
      const req = await request('api/link/generate', "POST", {url})
      if (req.error) {
        setMessage({ type: 'error', text: req.error.message})
      } else {
        setResult(req.link)
        setUrl('')
        setMessage(null)
      }
    } catch (e) {
      console.error(e);
      setMessage({ type: 'error', text: "Что-то пошло не так"})
    }

    setLoading(false)
  }, [url, loading])

  useEffect(() => {
    request('api/stats', "POST", {type: 'visit'})
  }, [])

  useEffect(() => {
    const doc = document.querySelector('.ActiveButtonBackground')
    if (!doc) return

    if (!loading) {
      doc.style.cursor = 'pointer'
      doc.style.transform = null
      doc.style.opacity = '0.2'
    } else {
      doc.style.transform = 'translateX(0)'
      doc.style.cursor = 'wait'
      doc.style.opacity = '1'
    }
  }, [loading])

  useEffect(() => {
    document.addEventListener('keypress', createLink)
    
    return () => document.removeEventListener('keypress', createLink)
  }, [createLink])

  return <div className="Main">
    <p className="HeaderText" style={{ textAlign: 'center'}}>Введите длинную ссылку</p>
    <div className="InputMenu">       
      <input 
        value={url}
    onChange={(e) => setUrl(e.target.value)}
    className="Input" 
    placeholder="https://"
        style={{ borderRadius: '30px 0 0 30px', borderRight: 0}}
      />

      <button
        className={"Button" + (url.length > 3 ? ' ActiveButton' : '')}
        onClick={createLink}
      >
        <div className="ActiveButtonBackground"/>
        <CreateButtonBody loading={loading}/>
      </button>
    </div>
  
    <Message message={message} setMessage={setMessage}/>

    <div className="ResultMenu">       
      <ResultLink result={result} setSnackbar={setSnackbar}/> 
    </div>
  </div>
}