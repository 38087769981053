import "./Banned.css"
import { ReactComponent as Virus} from "../../img/virus-infected.svg"

export default function Banned () {
    return <div className="App">
        <div className="Menu" style={{ alignItems: 'center', justifyContent: 'center'}}>
            <div className="BannedMenu">
                <Virus />
                <h1>Переход по ссылке заблокирован!</h1>
                <p>Ссылка ведет на подозрительный сайт, переход по ней может быть опасен! Если вы считаете что это ошибка свяжитесь со мной по <a href="https://хор-енотов.рф">ссылке</a></p>
            </div>
        </div>
    </div>
}