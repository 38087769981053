import "./Button.css"

const COLORS = {
    default: 'var(--default_button',
    positive: 'var(--positive_button',
    negative: 'var(--negative_button'
}

export default function Button({ children, onClick, fill = 'default', style = {}, disabled }) {
    return <button 
        disabled={disabled}
        style={{ background: COLORS[fill], ...style }}
        className="DefaultButton"
        onClick={!disabled ? onClick : undefined}
    >
        {children}
    </button>
}